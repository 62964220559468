require("./scss/style.scss");
$(document).ready(function(){$(window).scrollTop($(window).scrollTop()+1);var o=$("#nav-top").outerHeight();$(window).scroll(function(){$(this).scrollTop()>o?($("#header").addClass("header-fixed"),$(".navbar").css({"background-color":"rgba(255,255,255,1)","box-shadow":"0px 2px 5px rgba(0,0,0,0.1)"}),$(".dropdown-menu").css({"background-color":"rgba(255,255,255,1)"})):($("#header").removeClass("header-fixed"),$(".navbar").css("background-color","rgba(255,255,255,1)"),$(".dropdown-menu").css("background-color","rgba(255,255,255,1)"))}),$(window).scroll(function(){$(this).scrollTop()>o+50?$(".header-fixed").css({visibility:"visible",opacity:"1"}):$(".header-fixed").css({visibility:"visible",opacity:"1"})}),$(window).scroll(function(){$(this).scrollTop()>350?$(".scroll_top").fadeIn():$(".scroll_top").fadeOut()}),$(".scroll_top").click(function(){return $("html, body").animate({scrollTop:0},1500),!1})})

let width =  window.innerWidth;
document.getElementsByTagName('body').innerHTML = width;
// let height = window.innerHeight;
// document.getElementsByTagName('body').innerHTML = height;

// console.log(width)
// console.log(height)
// window.onresize = function(){
    let banners = document.getElementsByClassName("bannerAlt")
    let banner = [...banners];
    let widthTotal = window.innerWidth;
    let body = document.getElementsByTagName('body').innerHTML = widthTotal;
    banner.forEach(banner => { 
 if(body <= 426){
        var valueMobile = banner.setAttribute("width", 375);
        console.log("é Mobile")
    } else {
        var valueDesk = banner.setAttribute("width", 1400);
        console.log("Não é Mobile")
    }
      
    })
   
   
// }

